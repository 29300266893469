/* eslint-disable prefer-template */
// import axios from 'axios';
import moment from 'moment';
import { isEmail, isMobilePhone, isStrongPassword } from 'validator';
import parsePhoneNumber from 'libphonenumber-js';
// import Toast from 'react-hot-toast';

export const errorParser = (error, fallback = 'Unable to proceed') => {
  return error?.response?.data?.data?.error || error?.response?.data?.error || fallback;
};

export const throttle = (fn, wait) => {
  let time = Date.now();

  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

// Card Type Detection

export const detectCardType = (cardNumber) => {
    const patterns = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        amex: /^3[47][0-9]{13}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const cardType in patterns) {
        if (patterns[cardType].test(cardNumber)) {
            return cardType;
        }
    }

    return 'Unknown';
}

export const injectLink = (link, href = '') => {
  const typoElt = document.getElementById('typography.js');

  if (typoElt) {
    typoElt.insertAdjacentHTML('afterend', link);
  }
};

export const getFontsStr = (typography) => {
  let fontsStr = '';
  if (typography.options.googleFonts) {
    const fonts = typography.options.googleFonts.map((font) => {
      let str = '';
      str += font.name.split(' ').join('+');
      str += ':';
      str += font.styles.join(',');

      return str;
    });

    fontsStr = fonts.join('|');
  }

  return fontsStr;
};

export const getFontsLink = (fontsStr) => {
  const href = `//fonts.googleapis.com/css?family=${fontsStr}`;
  const link = `<link href="${href}" rel="stylesheet" type="text/css" />`;
  return { link, href };
};

export const injectFonts = (typography) => {
  const fontsStr = getFontsStr(typography);
  if (fontsStr) {
    const { link, href } = getFontsLink(fontsStr);
    injectLink(link, href);
  }
};

export const isEmpty = (obj) => {
  return (
    obj === undefined ||
    obj === null ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0) ||
    (typeof obj === 'string' && obj.trim().length === 0)
  );
};

export const isEmptyObject = (obj) => {
  return !(typeof obj === 'object' && Object.keys(obj).length > 0);
};

export const onlyDefined = (obj) => {
  const newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== '' && value !== 'undefined') {
      newObj[key] = value;
    }
  });

  return newObj;
};

/**
 * encodeB64
 *
 * encode string to base 64 using **`btoa`** library
 * @param {string} str
 * @returns {string}
 */
export const encodeB64 = (str) => {
  return btoa(str);
};

// const responseHandler = ({ data = {} }, show) => {
//   const { response, message, status_code: status, error, ...others } = data;
//   if (show) {
//     if (error) {
//       Toast.error(typeof response === 'string' ? response : message || 'Could be your network!');
//     } else {
//       Toast.success(typeof show === 'string' ? show : message || 'Success');
//     }
//   }
//   return { response, message, status, error, ...others };
// };

// const errorHandler = ({ response: { data } = { data: {} } }, show) => {
//   const { response, data: validate, message = 'Network Error', error, status_code: status } = data;
//   const m =
//     message === 'Validation Error.'
//       ? validate[0]
//       : typeof response === 'string'
//       ? response
//       : message || 'Could be your network!';
//   if (show) {
//     Toast.error(m);
//   }
//   return { response, message, status, error };
// };

// const preRequestCheck = () => {
//   return Promise.all([localGet(STORAGE.TOKEN_EXPIRE), localGet(STORAGE.TOKEN)]).then(
//     ([token_exp, token]) => {
//       if (token_exp < Date.now()) {
//         localRemove(Object.keys(STORAGE));
//         window.location.href = ROUTES.SIGN_IN.path;
//         Toast.error('Session expired. Please login');
//         return {};
//       }
//       return { headers: { authorization: `Bearer ${token}` } };
//     }
//   );
// };

// export const get = async (url, { authorize = false, showMessage = false }, options = {}) => {
//   const config = { ...options, ...(authorize === true ? await preRequestCheck() : {}) };

//   return axios
//     .get(url, { role: 'client', ...config })
//     .then((x) => responseHandler(x, showMessage))
//     .catch((x) => errorHandler(x, showMessage));
// };

// export const post = async (url, body, { authorize = false, showMessage = false }, options = {}) => {
//   const config = { ...options, ...(authorize === true ? await preRequestCheck() : {}) };

//   return axios
//     .post(url, body, { role: 'client', ...config })
//     .then((x) => responseHandler(x, showMessage))
//     .catch((x) => errorHandler(x, showMessage));
// };

// export const patch = async (
//   url,
//   body,
//   { authorize = false, showMessage = false },
//   options = {}
// ) => {
//   const config = { ...options, ...(authorize === true ? await preRequestCheck() : {}) };

//   return axios
//     .patch(url, body, { role: 'client', ...config })
//     .then((x) => responseHandler(x, showMessage))
//     .catch((x) => errorHandler(x, showMessage));
// };

// export const upload = async (
//   url,
//   body,
//   { authorize = false, showMessage = false },
//   options = {}
// ) => {
//   const config = { ...options, ...(authorize === true ? await preRequestCheck() : {}) };

//   return axios
//     .post(url, body, { role: 'client', ...config })
//     .then((x) => responseHandler(x, showMessage))
//     .catch((x) => errorHandler(x, showMessage));
// };

export const formatDate = (date, format = 'ddd MMM D YYYY h:ma') => {
  if (date) {
    return moment(date).format(format);
  }

  return date;
};
export const rcFormat = (val) => {
  return /^RC\d{7}$/i.test(val);
};

export function containsOnlyNumbers(str) {
  return /^\d+$/.test(str);
}
export const validator = (value, validate = '') => {
  const opts = validate.split('|');

  const errors = opts
    .map((opt) => {
      if (opt === 'required') {
        return (value || '').trim() ? null : 'Field required';
      }
      if (opt === 'email') {
        return isEmail(value) ? null : 'Enter correct email address';
      }
      if (opt === 'phone_number') {
        return isMobilePhone(value) ? null : 'Phone number invalid';
      }
      if (opt.startsWith('multi_phone_numbers')) {
        const [, sep = ',', count = 5] = opt.split(':');
        const numbers = value.split(sep) || [];
        const validity = numbers
          .map((v) => (isMobilePhone(v.trim(), null, { strictMode: true }) ? null : v.trim()))
          .filter((v) => v);
        return numbers.length <= parseInt(count, 10)
          ? validity.length === 0
            ? null
            : `Invalid phone numbers: ${validity.join(', ')}`
          : `You cannot enter more than ${count} numbers`;
      }
      if (opt === 'businessRegNumber') {
        return !rcFormat(value) ? 'Invalid Business Reg. Format' : null;
      }
      if (opt === 'onlyNumbers') {
        return !containsOnlyNumbers(value) ? 'Incorrect field' : null;
      }

      if (opt === 'expirationDate') {
        const date = value.split('/');
        // eslint-disable-next-line radix
        const [expirationMonth, expirationYear] = [parseInt(date[0]), parseInt(date[1])+2000];

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // January is 0

        if (expirationYear > currentYear) {
          return null;
          // eslint-disable-next-line no-else-return
        } else if (expirationYear === currentYear && expirationMonth >= currentMonth) {
          return null;
        } else {
          return 'Incorrect Date';
        }
      }

      // if (opt === 'password') {
      //   return matches(value, /^[a-zA-Z0-9]{3,10}$/)
      //     ? null
      //     : 'Password must contain letters and numbers only and between 3 to 10 characters';
      // }
      if (opt === 'cvv') {
        const cvvPattern = /^[0-9]{3,4}$/;
        return cvvPattern.test(value) ? null : 'Invalid CVV'
      }
      if (opt === 'cardNumber') {
        const formattedCard = value.replaceAll(' ', '');
        let sum = 0;
        let isEven = false;

        // eslint-disable-next-line no-plusplus
        for (let i = formattedCard.length - 1; i >= 0; i--) {
          let digit = parseInt(formattedCard.charAt(i), 10);

          if (isEven) {
            digit *= 2;
            if (digit > 9) {
              digit -= 9;
            }
          }
          sum += digit;
          isEven = !isEven;
        }
        // detectCardType(formattedCard);
        return sum % 10 === 0 ? null : 'Invalid Card Number';
      }
      if (opt === 'password') {
        return isStrongPassword(value)
          ? null
          : 'Password must contain 1 lowercase, 1 uppercase, 1 number, 1 special character and minimum of 8 characters';
      }
      return null;
    })
    .filter((e) => !!e);

  return errors;
};

export const maskPhoneNumber = (value, stars = 9) => {
  return value.replace(
    value.substr(0, value.length - stars),
    value.substr(0, value.length - stars).replace(/./g, '*')
  );
};

export const formatPhoneNumber = (msisdns = '', split) => {
  if (split) {
    const output = msisdns
      .split(split)
      .map((v) => v.trim())
      .filter((v) => !!v);
    return output.map((msisdn) => msisdn.replace('+234', '234-'));
  }

  return msisdns.replace('+234', '234-');
};
export const getDate = (date) => {
  let computedDate = null;
  switch (date) {
    case 'Today':
      computedDate = moment().startOf('day');
      break;
    case 'This Week':
      computedDate = moment().startOf('week');
      break;
    case 'This Month':
      computedDate = moment().startOf('month');
      break;
    case 'Last 30 Days':
      computedDate = moment().subtract(30, 'day');
      break;
    case 'This Year':
      computedDate = moment().startOf('year');
      break;
    default:
      break;
  }
  return computedDate;
};

export const extractCountryCode = (num) => {
  if (num[0] !== '') {
    const extracted = num?.map((n) => ({
      countryCode: parsePhoneNumber(n)?.countryCallingCode,
      phoneNumber: `${parsePhoneNumber(n)?.nationalNumber}`,
    }));
    return extracted;
  }
  return null;
};
export const reformatDataPlan = (plan) => {
  // const newData = [];
  // const detectedOperator = plan.map((p) => p.operator);
  const d = plan.reduce((acc, crr) => {
    acc[crr.operator] = {
      ...crr,
      phoneNumber: plan
        .map((p) => {
          return p.operator === crr.operator ? p.phoneNumber : null;
        })
        .filter((p) => p !== null),
    };
    return acc;
  }, {});
  return Object.values(d);
};
export const titleCase = (string) => {
  if (string === null || string === undefined) {
    return '';
    // eslint-disable-next-line no-else-return
  } else {
    const text = string.toLowerCase().split('_');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < text.length; i++) {
      text[i] = text[i][0].toUpperCase() + text[i].slice(1);
    }
    return text.join(' ');
  }
};

export const toCurrency = (n, code = 'NGN') => {
  n = typeof n === 'string' ? Number(n.replace(/[^0-9.]/g, '')) : n;

  if (n === 0 || n === null || n === undefined) return `${code} ${0}`;

  const getNumber = (value) => {
    return (
      `${code} ` +
      Number(value)
        .toFixed(2)
        .replace(/./g, function (c, i, a) {
          return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
        })
    );
  };

  if (n.toString().startsWith('-')) {
    const newNumber = getNumber(Math.abs(n));
    return `-${newNumber}`;
  }

  return n ? getNumber(n) : '';
};
